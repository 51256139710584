@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  color-scheme: light;
}

:root {
  --color-default-light: #fff;
  --color-default-dark: #cbd5e1;

  --color-cold-light: #d4f0f1;
  --color-cold-dark: #9ed8da;

  --color-clear-light: #fdffdd;
  --color-clear-dark: #f9f4c7;

  --color-cloudy-light: #eeeeee;
  --color-cloudy-dark: #dddddb;

  --color-rainy-light: #cab2e2;
  --color-rainy-dark: #976dd9;

  --color-hot-light: #f9cfdf;
  --color-hot-dark: #dd9fec;

  --color-location-blue: #100db1;
  --color-location-yellow: #ffca01;
  --color-location-turquoise: #2ab6ca;
  --color-location-red: #ff0000;
}

@layer base {
  h1 {
    @apply text-3xl lg:text-4xl font-semibold;
  }
  h2 {
    @apply text-2xl lg:text-3xl font-semibold;
  }
  h3 {
    @apply text-xl lg:text-2xl font-semibold;
  }
  h4 {
    @apply text-lg lg:text-xl;
  }
  p {
    @apply text-sm text-default-text;
  }
}

@layer components {
  .icon {
    @apply text-default-text text-xl w-6;
  }

  .active-link {
    @apply text-accent-red font-semibold;
  }

  .form-input {
    @apply w-full px-2 py-2 border border-solid outline-none border-gray-300 rounded-md;
  }

  .submit-btn {
    @apply bg-accent-red text-default-text rounded-md cursor-pointer py-2 px-10;
  }

  .error-message {
    @apply text-accent-red text-xs;
  }
}

body {
  margin: 0;
  font-family: "Poppins", Helvetica, sans-serif;
}

.icon-spin-open {
  animation: spin-open 0.5s forwards;
}

.icon-spin-close {
  animation: spin-close 0.5s forwards;
}

@keyframes spin-open {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes spin-close {
  from {
    transform: rotate(225deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* -------------------------- BACKGROUNDS -------------------------- */
.kode-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.default-bg {
  background-color: grey;
}

.our-footprint-bg {
  background-image: url("./assets/img/our-footprint-bg.jpeg");
}

.kit-digital-bg {
  background-image: url("./assets/img/background-kit-digital.jpg");
}

.team-bg {
  background-image: url("./assets/img/team-bg.png");
}

.get-bg {
  background-image: url("./assets/img/get-in-touch-bg.jpg");
  background-position: bottom;
}

.services-bg {
  background-color: #eceff1;
}

@media (max-width: 768px) {
  .home-bg {
    background-position: 60% center;
  }
}

/* -------------------------- PAGE ANIMATION -------------------------- */

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/* -------------------------- SWIPER -------------------------- */

.swiper {
  width: 900px;
  height: 300px;
  padding: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.custom-swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  position: absolute;
  bottom: -50px !important;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.swiper-pagination-bullet {
  background: #fff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #d94f4f !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
}

@media (max-width: 1020px) {
  .swiper {
    width: 600px;
  }
}

@media (max-width: 640px) {
  .swiper {
    width: 300px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

.relative .card {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.relative.flipped .card {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  backface-visibility: hidden;
  transform: translateZ(1px);
}

.card-back {
  transform: rotateY(180deg) translateZ(1px);
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: none !important;
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

/* -------------------------- CARD HOVER -------------------------- */

.hover-animation {
  transition: all 500ms ease-in-out;
}
.hover-animation:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}

/* -------------------------- ANIMATION -------------------------- */

@keyframes zoomInAnimation {
  from {
    transform: scale(0.3);
  }
  to {
    transform: scale(1);
  }
}

.zoom-in-effect {
  animation-name: zoomInAnimation;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}

.zoom-in-effect-delay {
  animation-name: zoomInAnimation;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-delay: 2000ms;
}

@keyframes fadeInAndFocus {
  from {
    opacity: 0;
    filter: blur(5px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
}

.fade-in-focus-effect {
  animation: fadeInAndFocus 4s ease-in-out forwards;
}

.fade-in-focus-effect-delay {
  animation: fadeInAndFocus 3s ease-in-out forwards;
  animation-delay: 1000ms;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

/* -------------------------- HERO SLIDER -------------------------- */

.index-module_Wrapper__xqqKi {
  z-index: 2 !important;
}

.progress-bar {
  z-index: 2;
}

/* -------------------------- WORLD MAP -------------------------- */

.blue-location {
  fill: var(--color-location-blue);
}

.yellow-location {
  fill: var(--color-location-yellow);
}

.turquoise-location {
  fill: var(--color-location-turquoise);
}

.red-location {
  fill: var(--color-location-red);
}

.blue-location-bg {
  background-color: var(--color-location-blue);
}

.yellow-location-bg {
  background-color: var(--color-location-yellow);
}

.turquoise-location-bg {
  background-color: var(--color-location-turquoise);
}

.red-location-bg {
  background-color: var(--color-location-red);
}


/* -------------------------- INDUSTRY CARDS -------------------------- */


.gradient-border {
  position: relative;
  border-radius: 50%;
  padding: 5px;
  background: linear-gradient(45deg, #7b4fff 0%, #e7ff4f 100%);
}

.industry-border {
  position: relative;
  border-radius: 50%;
  padding: 6px;
  background: #37474f;
}

.image-wrapper {
  position: relative;
  border-radius: 50%;
  padding: 4px;
}

.image-wrapper::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #7b4fff 0%, #e7ff4f 100%);
  clip-path: polygon(
    50% 0%,
    100% 0,
    100% 100%,
    0 100%,
    0 50%,
    50% 50%,
    50% 50%
  );
}


/* -------------------------- SOLUTIONS SWIPER -------------------------- */

.solutions-swiper {
  width: 100%;
  height: 500px;
}

.solutions-swiper-slide {
  width: 300px;
  height: 500px;
}


